import Vue from 'vue'
import WheelIndicator from '@/plugins/wheelIndicator'
import { BLOG, BROWSE_JOBS, FREELANCERS_LIST, SERVICES } from '@/constants/routes'

export default Vue.extend<any, any, any, any>({
  data () {
    return {
      BLOG,
      BROWSE_JOBS,
      FREELANCERS_LIST,
      SERVICES,
      activeStep: 1,
      changing: false,
      lastTouchY: 0,
    }
  },
  mounted () {
    if ('ontouchstart' in window) {
      const targets = this.$refs.targets
      targets.addEventListener('touchstart', this.onTouchStart)
      targets.addEventListener('touchend', this.onTouchEnd)
      targets.addEventListener('touchmove', this.onTouchMove)
    } else {
      const indicator = new WheelIndicator({
        elem: this.$refs.targets,
        callback: this.changeStepW,
      })
    }
  },
  methods: {
    getTransfromEl (el: HTMLElement) {
      return Number(((el.style.transform || '').match(/-?\d+/g) || [0])[0])
    },
    getActiveStep (targets: Array<HTMLElement>) {
      return targets.findIndex(node => node.classList.contains('section-active'))
    },
    onTouchStart (e: any) {
      if (e) {
        this.lastTouchY = e.changedTouches[0].clientY
      }
    },
    onTouchMove (e: any) {
      e.preventDefault()
      e.stopPropagation()
    },
    onTouchEnd (e: any) {
      if (e) {
        const currentY = e.changedTouches[0].clientY
        if (this.lastTouchY - 10 > currentY) {
          this.changeNextStep()
          e.preventDefault()
          e.stopPropagation()
        } else if (this.lastTouchY + 10 < currentY) {
          this.changePrevStep()
          e.preventDefault()
          e.stopPropagation()
        }
      }
    },
    changeStepW (e: any) {
      if (!this.changing) {
        this.changing = true
        if (e.direction === 'up') {
          this.changePrevStep()
        } else {
          this.changeNextStep()
        }
        setTimeout(() => {
          this.changing = false
        }, 300)
      }
    },
    changePrevStep () {
      let targets = [...this.$el.querySelectorAll('.target')]
      const targetH = targets[0].clientHeight
      let active = this.getActiveStep(targets)
      if (active - 2 < 0) {
        const newTarget = targets[active + 2].cloneNode(true)
        newTarget.style = `transform: translateY(${this.getTransfromEl(targets[0]) - 100}%)`
        this.$refs.container.prepend(newTarget)
        targets = [newTarget].concat(targets)
        active += 1
      }
      targets[active].classList.remove('section-active')
      targets[active - 1].classList.add('section-active')
      this.activeStep = (this.activeStep + 3) % 4
      const y = this.getTransfromEl(this.$refs.container)
      this.$refs.container.style = `transform: translateY(${y + targetH}px)`
      this.changeActiveImg()
    },
    changeNextStep () {
      let targets = [...this.$el.querySelectorAll('.target')]
      const targetH = targets[0].clientHeight
      let active = targets.findIndex(node => node.classList.contains('section-active'))
      if (active + 2 >= targets.length) {
        const newTarget = targets[active - 2].cloneNode(true)
        const lastY = this.getTransfromEl(targets[targets.length - 1])
        newTarget.style = `transform: translateY(${lastY === 0 ? 400 : lastY + 100}%)`
        this.$refs.container.appendChild(newTarget)
      }
      targets[active].classList.remove('section-active')
      targets[active + 1].classList.add('section-active')
      this.activeStep = (this.activeStep + 5) % 4
      const y = this.getTransfromEl(this.$refs.container)
      this.$refs.container.style = `transform: translateY(${y - targetH}px)`
      this.changeActiveImg()
    },
    changeActiveImg () {
      const icons = [...this.$refs.icons.querySelectorAll('img')]
      const activeIcon = icons.find((img) => img.classList.contains('img-active'))
      activeIcon.classList.remove('img-active')
      icons[this.activeStep].classList.add('img-active')
    }
  }
})
