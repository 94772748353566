import Vue from 'vue'
import { mapActions } from 'vuex'
import { BLOG, BROWSE_JOBS, FREELANCERS_LIST, SERVICES } from '@/constants/routes'
import { feedbackSend } from '@/api/feedback'
import snackMixin from '@/mixins/snackMixin'
import canonicalMixin from '@/mixins/canonicalMixin'
import FindMoreSection from './FindMoreSection/FindMoreSection.vue'

export default Vue.extend<any, any, any, any>({
  mixins: [snackMixin, canonicalMixin],
  components: {
    FindMoreSection,
  },
  data () {
    return {
      BLOG,
      BROWSE_JOBS,
      FREELANCERS_LIST,
      SERVICES,
      name: '',
      phone: '',
      email: '',
      message: '',
      feedbackSending: false,
      feedbackSent: false,
    }
  },
  methods: {
    ...mapActions({
      openModal: 'ui/openModal',
    }),
    onContactUsClick () {
      this.$router.replace({ hash: '#contact-us' })
      const nameInput = this.$refs.nameInput
      if (nameInput) {
        nameInput.focus()
      }
    },
    async sendFeedback () {
      if (!await this.$validator.validateAll()) return false
      try {
        this.feedbackSending = true
        await feedbackSend({
          'site_id': 'laborx.com',
          'name': this.name,
          'phone': this.phone,
          'email': this.email,
          'message': this.message
        })
        this.feedbackSent = true
        this.openModal({
          component: 'lx-success-modal',
          props: {
            text: 'Your message has been successfully sent',
          }
        })
      } catch (err) {
        this.openSnackbar({
          type: this.SnackTypes.FAILURE,
          text: 'Error submitting feedback. Please try again.',
        })
      }
      this.feedbackSending = false
    }
  },
  metaInfo () {
    return {
      title: 'About Us',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: 'LaborX is an on-demand work platform built by Australian blockchain company Chrono.tech'
        },
        {
          vmid: 'og:title',
          property: 'og:title',
          content: 'About Us',
        },
        {
          vmid: 'og:description',
          property: 'og:description',
          content: 'LaborX is an on-demand work platform built by Australian blockchain company Chrono.tech'
        },
        {
          vmid: 'og:type',
          property: 'og:type',
          content: 'website',
        },
        {
          vmid: 'og:image',
          property: 'og:image',
          content: `${process.env.VUE_APP_FRONTEND_URL}/static/images/about-us-preview.png`
        },
      ],
      link: [this.canonicalLink]
    }
  },
})
